<template>
    <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
    >
        <div>
            <b-row>
                <b-col lg="12" md="12" sm="12">
                    <b-form-file
                        v-model="documents"
                        placeholder="Elija los documentos o déjalos ir aquí..."
                        drop-placeholder="Suelta los documentos aquí..."
                        multiple
                        browse-text="Selecciona"
                        class="form-files"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-button variant="primary" class="mt-2 mr-1" @click="uploadFiles">
                        Subir documentos
                    </b-button>

                    <b-button variant="primary" class="mt-2 mr-1" @click="createFolder">
                        Crear Carpeta
                    </b-button>
                </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
                <b-col cols="12">
                    <table role="table" aria-busy="false" aria-colcount="3" class="table b-table table-none" data-v-5cff0742="">
                        <thead role="rowgroup" class="">
                        <tr role="row" class="">
                            <th role="columnheader" scope="col" aria-colindex="1" class="">
                                <div>Documentos</div>
                            </th>
                            <th role="columnheader" scope="col" aria-colindex="3" class="">
                                <div>Opciones</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody role="rowgroup">
                        <tr v-if="folder!==''">
                            <td aria-colindex="1" role="cell" class="">
                                <a class="text-primary" @click="getDocuments(previousFolder)">
                                    <feather-icon
                                        icon="ChevronsLeftIcon"
                                        size="16"
                                    />
                                    Ir a la carpeta anterior
                                </a>
                            </td>
                            <td aria-colindex="2" role="cell" class="">
                                <div class="m-1">
                                    <a @click="getDocuments(previousFolder)">
                                        <feather-icon
                                            icon="FolderIcon"
                                            class="text-primary"
                                            size="16"
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="document in list_documents" :key="document.name" role="row" class="">
                            <td aria-colindex="1" role="cell" class="" style="max-width: 70px !important;">
                                <a v-if="document.type==='file'"
                                   :href="baseUrl+'storage/equipment/'+equipment.id+'/equipment_client_documents'+folder+'/'+document.name"
                                   target="_blank">
                                    {{ document.name }}
                                </a>
                                <a v-else-if="document.type==='folder'"
                                   class="text-primary"
                                   @click="getDocuments(folder=='' ? document.name : folder+document.name)">
                                    {{ document.name }}
                                </a>
                            </td>
                            <td aria-colindex="2" role="cell" class="" >
                                <div class="m-1" style="min-width: 48px;">
                                    <a v-if="document.type==='file'"
                                       :href="baseUrl+'storage/equipment/'+equipment.id+'/equipment_client_documents'+folder+'/'+document.name"
                                       target="_blank">
                                        <feather-icon
                                            icon="EyeIcon"
                                            size="16"
                                        />
                                    </a>

                                    <a v-else-if="document.type==='folder'"
                                       @click="getDocuments(folder=='' ? document.name : folder+document.name)">
                                        <feather-icon
                                            icon="FolderIcon"
                                            class="text-primary"
                                            size="16"
                                        />
                                    </a>

                                    <feather-icon
                                        icon="TrashIcon"
                                        size="16"
                                        class="cursor-pointer ml-1 text-red"
                                        @click="deleteDocument(document, document.type)"
                                    />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>

<script>
import { BButton, BRow, BOverlay, BCol, BFormFile } from 'bootstrap-vue'
import { mapState } from "vuex"
import { baseUrl } from "@/constants/app"
import Swal from "sweetalert2"

export default {
    name: "FormClientFolder",
    components: {
        BRow,
        BCol,
        BButton,
        BOverlay,
        BFormFile,
    },
    data() {
        return {
            showLoading: false,
            folder: '',
            list_documents: [],
            documents: [],

            baseUrl,
        }
    },
    computed: {
        equipment() {
            return this.$store.state.equipment.equipment
        },
        previousFolder() {
            return this.folder.substring(0, this.folder.lastIndexOf('/'))
        }
    },
    watch: {
        'equipment.id'() {
            this.getDocuments(this.folder)
        }
    },
    created() {
        if (this.equipment.id)
            this.getDocuments(this.folder)
    },
    methods: {
        uploadFiles: function (){
            this.showLoading = true

            this.$store.dispatch('equipment/saveDocumentsClientFolder', {data: this.formatData()})
                .then(() => {
                    this.showLoading = false
                    this.getDocuments(this.folder)
                })
                .catch(error => {
                    console.log(error)
                    this.showLoading = false
                })
        },
        createFolder: function () {
            this.$swal({
                title: 'Nombre de la carpeta',
                input: 'text',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Crear',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true,
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('equipment/createDocumentsClientFolder', {data: {
                        'nombre':result.value,
                        'folder': this.folder,
                        'equipment_id': this.equipment.id
                    }})
                        .then(() => {
                            this.showLoading = false
                            this.getDocuments(this.folder)
                        })
                        .catch(error => {
                            console.log(error)
                            this.showLoading = false
                        })
                }
            })
        },
        formatData: function (){
            var formData = new FormData()

            for (var i = 0; i < this.documents.length; i++) {
                formData.append('documents['+i+']', this.documents[i])
            }

            formData.append('id_equipment', this.equipment.id)
            formData.append('folder', this.folder)

            return formData
        },
        getDocuments: function (folder){
            this.showLoading = true

            this.folder = folder
            this.list_documents = []

            this.$store.dispatch('equipment/listDocumentsClientFolder', {id: this.equipment.id, folder: this.folder})
                .then(response => {
                    response.forEach(element => this.list_documents.push( element ))
                    this.showLoading = false
                })
                .catch(error => {
                    console.log(error)
                    this.showLoading = false
                })
        },
        deleteDocument: function (document, type){
            if(type == 'folder') {
                Swal.fire({
                    title: "Está seguro de borrar la carpeta?",
                    text: "Si borras la carpeta se borraran todos los archivos que estén dentro!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí!",
                    cancelButtonText: "Cancelar",
                }).then(result => {
                    if (result.value) {
                        this.dispatchDeleteFile(document)
                    }
                })
            } else{
                this.dispatchDeleteFile(document)
            }
        },
        dispatchDeleteFile: function (document) {
            this.showLoading = true

            this.$store.dispatch('equipment/deleteDocumentsClientFolder', {id: this.equipment.id, document: document, folder: this.folder})
                .then(response => {
                    this.showLoading = false

                    if (response)
                        this.getDocuments(this.folder)
                })
                .catch(error => {
                    console.log(error)
                    this.showLoading = false
                })
        }
    }
}
</script>

<style>
.form-files{
    height: 100px;
}

.form-files .custom-file-label::after {
    display: none !important;
}
</style>