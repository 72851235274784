import { render, staticRenderFns } from "./sheetClientFolder.vue?vue&type=template&id=0bcb9b62&"
import script from "./sheetClientFolder.vue?vue&type=script&lang=js&"
export * from "./sheetClientFolder.vue?vue&type=script&lang=js&"
import style0 from "./sheetClientFolder.vue?vue&type=style&index=0&id=0bcb9b62&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports