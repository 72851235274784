<template>
    <section class="app-ecommerce-details">
        <div class="mb-2">
            <router-link v-if="can('create_intervention')"
                :to="{ name: 'create_interventions', params: { id: this.$route.params.id } }">
                <b-button class="btn btn-primary m-05 mb-sm-0" type="button">
                    Crear intervención
                </b-button>
            </router-link>

            <router-link :to="{ name: 'list_interventions_from_equipment', params: { id: this.$route.params.id, stateOpened: true } }">
                <b-button class="btn btn-success m-05 mb-sm-0" type="button">
                    Ver intervenciones abiertas
                </b-button>
            </router-link>
            <router-link :to="{ name: 'list_interventions_from_equipment', params: { id: this.$route.params.id, stateOpened: false } }">
                <b-button class="btn btn-primary m-05 mb-sm-0" type="button">
                    Ver intervenciones
                </b-button>
            </router-link>

            <router-link v-if="can('create_equipment_delete_request')"
                :to="{ name: 'form_equipment_delete_request', params: { id: this.$route.params.id } }">
                <b-button class="btn btn-danger m-05 mb-sm-0" type="button">
                    Solicitar baja
                </b-button>
            </router-link>
        </div>

        <b-card no-body>
            <b-card-body>
                <b-row class="mx-0">
                    <b-col cols="12" md="5" class="align-items-center justify-content-center mb-2 mb-md-0"
                        v-if="equipment.equipment_photo || equipment.plate_photo">
                        <b-carousel id="carousel-example-generic" controls indicators>
                            <b-carousel-slide v-if="equipment.equipment_photo"
                                :img-src="baseUrl + 'storage/' + equipment.equipment_photo" style="border:-radius: 5px; max-height: 50vh; object-fit: cover;"/>
                            <b-carousel-slide v-if="equipment.plate_photo"
                                :img-src="baseUrl + 'storage/' + equipment.plate_photo" />
                        </b-carousel>
                    </b-col>
                    <b-col cols="12" md="7">
                        <b-card-text class="item-company mb-0">
                            <b-row v-if="equipment.location" class="mx-0">
                                <b-col cols="12" class="p-0">
                                    <label class="d-block font-weight-bolder">Numero de serie</label>
                                    <p>{{ equipment.serial_number }}</p>
                                </b-col>
                            </b-row>
                            <b-row v-if="equipment.location" class="mx-0">
                                <b-col cols="12" class="p-0">
                                    <label class="d-block font-weight-bolder">Ubicación</label>
                                    <p>{{ equipment.location }}</p>
                                </b-col>
                            </b-row>
                            <b-row v-if="equipment.description" class="mx-0">
                                <b-col cols="12" class="p-0">
                                    <label class="d-block font-weight-bolder">Descripción</label>
                                    <p>{{ equipment.description }}</p>
                                </b-col>
                            </b-row>
                            <b-row v-if="equipment.client && equipment.client.name" class="mx-0">
                                <b-col cols="12" class="p-0">
                                    <label class="d-block font-weight-bolder">Cliente</label>
                                    <p>{{ equipment.client.name }}</p>
                                </b-col>
                            </b-row>
                            <b-row v-if="equipment.tags && equipment.tags.length" class="mx-0">
                                <b-col cols="12" class="p-0">
                                    <label class="d-block font-weight-bolder">TAG</label>
                                    <p v-if="equipment.tags" class="m-0">
                                        {{ equipment.tags.find(x => x.id === equipment.tag_id) ? equipment.tags.find(x =>
                                            x.id === equipment.tag_id).code : '' }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <b-card-actions title="Documentación técnica" action-collapse :collapsed="true">
            <sheet-technical-documentation />
        </b-card-actions>
        <b-card-actions title="Informes de reparación" action-collapse :collapsed="true">
            <sheet-repair-reports />
        </b-card-actions>
        <b-card-actions title="Informes predictivos" action-collapse :collapsed="true">
            <sheet-predictive-reports />
        </b-card-actions>
        <b-card-actions title="Certificaciones" action-collapse :collapsed="true">
            <sheet-certifications />
        </b-card-actions>
        <b-card-actions title="Albaranes" action-collapse :collapsed="true">
            <sheet-delivery-notes />
        </b-card-actions>
        <b-card-actions title="Documentos Carpeta Interna" action-collapse :collapsed="true" v-if="can('see_internal_folder')">
            <sheet-internal-folder />
        </b-card-actions>
        <b-card-actions title="Documentos Carpeta Cliente" action-collapse :collapsed="true">
            <sheet-client-folder />
        </b-card-actions>
    </section>
</template>
  
<script>

import {
    BCard, BCardBody, BRow, BCol, BCardText, BButton, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { createHelpers } from "vuex-map-fields"
import { mapState } from "vuex"
import { baseUrl } from "@/constants/app"
import { INTERVENTIONS_CREATE } from "@/router/web/constants"

import helpers from "@/resources/helpers/helpers"

import SheetLocationHistory from "@/views/equipments/sheet/components/sheetLocationHistory"
import SheetTechnicalDocumentation from "@/views/equipments/sheet/components/sheetTechnicalDocumentation"
import SheetRepairReports from "@/views/equipments/sheet/components/sheetRepairReports"
import SheetPredictiveReports from "@/views/equipments/sheet/components/sheetPredictiveReports"
import SheetCertifications from "@/views/equipments/sheet/components/sheetCertifications"
import SheetDeliveryNotes from "@/views/equipments/sheet/components/sheetDeliveryNotes"
import SheetClientFolder from "@/views/equipments/sheet/components/sheetClientFolder"
import SheetInternalFolder from "@/views/equipments/sheet/components/sheetInternalFolder"

const { mapFields } = createHelpers({
    getterType: 'equipment/getField',
})

export default {
    name: "ListView",
    directives: {
        Ripple,
    },
    components: {
        // BSV
        BCard,
        BCardBody,
        BRow,
        BCol,
        BCardText,
        BButton,
        BCarousel,
        BCarouselSlide,
        BCardActions,

        SheetClientFolder,
        SheetInternalFolder,
        SheetDeliveryNotes,
        SheetRepairReports,
        SheetCertifications,
        SheetLocationHistory,
        SheetPredictiveReports,
        SheetTechnicalDocumentation,
    },

    computed: {
        ...mapState('equipment', ['equipment']),
        ...mapFields({ equipment: 'equipment', }),
    },
    data() {
        return {
            showLoading: false,
            list_documents: [],
            baseUrl,
            num_tab: 0,
            INTERVENTIONS_CREATE,
        }
    },
    created() {
        this.getEquipment()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        tabChanged(newTabIndex, prevTabIndex) {
            this.num_tab = newTabIndex
        },
        getEquipment() {
            this.showLoading = true

            this.$store.dispatch('equipment/getEquipment', {
                id: this.$route.params.id,
                relations: ['tags', 'activeTag', 'equipmentCertification', 'equipmentDeliveryNote', 'client',
                    'equipmentPredictiveReport', 'equipmentRepairReport', 'equipmentTecnicalDocumentation'
                ]
            }).then(response => {
                this.showLoading = false
            }).catch(error => {
                this.showLoading = false
                this.$router.push({name: 'list_equipments'});
                helpers.showToast(error.message, 'MonitorIcon', 'danger');
            })
        },
    }
}
</script>
  
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
  