<template>
    <b-row>
        <b-col cols="12" class="p-0">
            <b-card>
                <b-row class="m-0">
                    <b-col cols="12" class="overflow-auto p-0">
                        <table role="table" aria-busy="false" aria-colcount="3" class="table b-table table-none"
                            data-v-5cff0742="">
                            <thead role="rowgroup" class="">
                                <tr role="row" class="">
                                    <th role="columnheader" scope="col" aria-colindex="1" class="">
                                        <div>TAG</div>
                                    </th>
                                    <th role="columnheader" scope="col" aria-colindex="1" class="">
                                        <div>Activo</div>
                                    </th>
                                    <th role="columnheader" scope="col" aria-colindex="3" class="">
                                        <div>Fecha</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody role="rowgroup">
                                <tr v-for="tag in equipment.tags" :key="tag.id" role="row" class="">
                                    <td aria-colindex="1" role="cell" class="">
                                        <p>{{ tag.code }}</p>
                                    </td>
                                    <td aria-colindex="1" role="cell" class="">
                                        <p>{{ tag.pivot.active ? 'Si' : 'No' }}</p>
                                    </td>
                                    <td aria-colindex="2" role="cell" class="">
                                        <p>{{ new Date(tag.created_at).toLocaleDateString("es-ES") }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCol, BRow } from "bootstrap-vue"
import { baseUrl } from "@/constants/app"
import { mapState } from "vuex"

export default {
    name: "SheetLocationHistory",
    components: {
        BCol,
        BRow,
        BCard,
    },
    data() {
        return {
            showLoading: false,
            list_documents: [],
            baseUrl,
        }
    },
    computed: {
        ...mapState('equipment', ['equipment']),
    },
}
</script>

<style scoped></style>